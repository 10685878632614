<template>
  <div class="enterprise-auth-form">
    <a-form :form="form" @submit="handleSubmit">
      <!--企业名称-->
      <div class="center">
        <a-form-item label="企业名称" v-bind="formItemLayout">
          <a-input
            size="large"
            placeholder="请输入企业名称"
            v-decorator="['companyName', formConfig.companyName]"
          />
        </a-form-item>
        <!--企业地址-->
        <a-form-item label="企业地址" v-bind="formItemLayout">
          <a-input
            size="large"
            placeholder="请输入企业地址"
            v-decorator="['companyAddress', formConfig.companyAddress]"
          />
        </a-form-item>
        <!--企业证件类型-->
        <a-form-item label="企业证件类型" v-bind="formItemLayout">
          <a-select
            size="large"
            v-decorator="['companyCertType', formConfig.companyCertType]"
          >
            <a-select-option
              v-for="(item, key) in CertificatesTypes"
              :key="key"
              :value="Number(key)"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <!--统一社会信用代码/组织机构代码-->
        <a-form-item
          label="统一社会信用代码/组织机构代码"
          v-bind="formItemLayout"
        >
          <a-input
            size="large"
            placeholder="请输入统一社会信用代码/组织机构代码"
            v-decorator="['companySocialCode', formConfig.companySocialCode]"
          />
        </a-form-item>
        <!--上传企业证件附件-->
        <a-form-item label="上传企业证件附件" v-bind="formItemLayout">
          <div class="upload-container">
            <i v-if="!companyCertImage" class="iconfont icon-daochu-11"></i>
            <a-upload
              class="img-uploader"
              :class="{ 'has-upload': companyCertImage }"
              :show-upload-list="false"
              :custom-request="customRequest"
              :beforeUpload="beforeUpload"
              :file-list="fileList"
              v-decorator="['companyIdentify', formConfig.companyIdentify]"
              @change="handleChange"
            >
              <img v-if="companyCertImage" :src="previewImgUrl" alt="" />

              <a-button
                v-else
                type="primary"
                shape="round"
                :style="uploadBtnStyle"
                >点击上传</a-button
              >
            </a-upload>
            <span
              class="error-tip"
              v-if="companyCertImage && uploadErrorText"
              >{{ uploadErrorText }}</span
            >
          </div>
          <div class="upload-tip">
            图片格式要求jpg、jpeg、bmp、png，不超过10M，
            可以添加“e路播备案”等水印，但不能遮挡关键信息，例如姓名、证件号。
          </div>
        </a-form-item>
      </div>

      <!---------------------------------------->
      <div class="divide"></div>
      <div class="center">
        <!--法人姓名-->
        <a-form-item label="法人姓名" v-bind="formItemLayout">
          <a-input
            size="large"
            placeholder="请输入法人姓名"
            v-decorator="['realName', formConfig.realName]"
          />
        </a-form-item>
        <!--证件类型-->
        <a-form-item
          :label="$t('layout.user.auth.form.certificates.title')"
          v-bind="formItemLayout"
        >
          <a-select size="large" v-decorator="['idType', formConfig.idType]">
            <a-select-option
              v-for="(item, key) in PersonalCertTypes"
              :key="key"
              :value="Number(key)"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <!--证件号码-->
        <a-form-item
          :label="$t('layout.user.auth.form.no.title')"
          v-bind="formItemLayout"
        >
          <a-input
            size="large"
            :placeholder="$t('layout.user.auth.form.no.placeholder')"
            v-decorator="['idCard', formConfig.idCard]"
          />
        </a-form-item>
        <!--手机验证码-->
        <a-form-item
          :label="$t('layout.user.auth.form.phone.title')"
          v-bind="formItemLayout"
        >
          <a-row type="flex">
            <a-col span="15">
              <a-input
                size="large"
                class="verify-code-input"
                placeholder="请输入短信验证码"
                v-decorator.trim="['smsCode', formConfig.smsCode]"
                @search="getSmsCode"
                @pressEnter="handleSubmit"
              >
              </a-input>
            </a-col>

            <a-col span="9">
              <a-button
                size="large"
                class="verify-code-button"
                type="primary"
                ghost
                block
                @click="getSmsCode"
                :disabled="sendSmsCode"
              >
                <span v-if="!sendSmsCode">获取验证码</span>
                <span v-else>重新获取({{ countDownNum }}s)</span>
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>
        <!--声明-->
        <a-form-item class="statement" v-bind="tailFormItemLayout">
          <!--<a-row>-->
          <!--<a-col>-->
          <a-checkbox
            class="agreement"
            v-decorator="[
              'agreement',
              {
                valuePropName: 'checked',
                rules: [{ validator: this.handleAgrmet }],
              },
            ]"
            >{{ $t("layout.user.auth.form.statement") }}
          </a-checkbox>
          <!--</a-col>-->
          <!--<span>&nbsp;{{ $t("layout.user.auth.form.statement") }}</span>-->
          <!--</a-row>-->
        </a-form-item>
        <!--提交按钮-->
        <a-form-item v-bind="tailFormItemLayout">
          <a-button
            block
            size="large"
            type="primary"
            html-type="submit"
            :style="submitBtnStyle"
            shape="round"
            >提交信息</a-button
          >
        </a-form-item>
      </div>
    </a-form>
    <auth-submit-success-modal ref="authSubmitSuccessModal" />
  </div>
</template>

<script>
import {
  Input,
  Select,
  Form,
  Button,
  Checkbox,
  Upload,
  Icon,
  message,
  Row,
  Col,
} from "ant-design-vue";
import { convertPxToVw } from "@/utils/format";
import { uploadFile } from "@/request/file";
import { CertificatesTypes, PersonalCertTypes } from "@/config/layout";
import { AcceptImgType, UploadImgLimit } from "@/config/system";
import { cert, personalAuth, smsCode } from "@/request/user";
import { mapGetters } from "vuex";
import AuthSubmitSuccessModal from "@/views/user/AuthSubmitSuccessModal";
import {
  companyAddressRule,
  companyNameRule,
  companySocialCodeRule,
  idCardRule,
  realNameRule,
  smsCodeRule,
} from "@/config/rules";

export default {
  components: {
    AuthSubmitSuccessModal,
    [Input.name]: Input,
    [Input.Search.name]: Input.Search,
    [Select.name]: Select,
    [Select.Option.name]: Select.Option,
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Upload.name]: Upload,
    [Icon.name]: Icon,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  data() {
    return {
      CertificatesTypes,
      PersonalCertTypes,
      uploadErrorText: "",
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 12,
            offset: 10,
          },
        },
      },
      submitBtnStyle: {
        // width: convertPxToVw(320),
        // marginLeft: convertPxToVw(220),
        // marginLeft: "35%",
      },
      resendBtnStyle: {
        height: convertPxToVw(40),
        fontSize: convertPxToVw(14),
      },
      uploadBtnStyle: {
        height: convertPxToVw(36),
        width: convertPxToVw(140),
        marginTop: convertPxToVw(8),
        marginBottom: convertPxToVw(14),
      },
      previewImgUrl: "",
      companyCertImage: "",
      fileList: [],
      countDownNum: 59,
      sendSmsCode: false,
      formConfig: {
        companyName: {
          rules: [
            {
              required: true,
              message: "请输入企业名称",
            },
            {
              pattern: companyNameRule,
              message: "请输入正确的企业名称",
            },
          ],
        },
        companyAddress: {
          rules: [
            {
              required: true,
              message: "请输入企业地址",
            },
            {
              pattern: companyAddressRule,
              message: "请输入正确的企业地址",
            },
          ],
        },
        companyCertType: {
          rules: [
            {
              required: true,
              message: "请选择企业证件类型",
            },
          ],
        },
        companySocialCode: {
          rules: [
            {
              required: true,
              message: "请输入统一社会信用代码/组织机构代码",
            },
            {
              pattern: companySocialCodeRule,
              message: "请输入正确的统一社会信用代码/组织机构代码",
            },
          ],
        },
        companyIdentify: {
          rules: [
            {
              required: true,
              message: "请上传企业证件附件",
            },
          ],
        },
        realName: {
          rules: [
            {
              required: true,
              message: "请输入法人姓名",
            },
            {
              pattern: realNameRule,
              message: "请输入正确的法人姓名",
            },
          ],
        },

        idType: {
          rules: [
            {
              required: true,
              message: "请选择证件类型",
            },
          ],
        },
        idCard: {
          rules: [
            {
              required: true,
              message: this.$t("layout.user.auth.form.no.placeholder"),
            },
            {
              pattern: idCardRule,
              message: "请输入正确的证件号码",
            },
          ],
        },
        smsCode: {
          rules: [
            {
              required: true,
              message: "请输入短信验证码",
            },
            {
              pattern: smsCodeRule,
              message: "短信验证码格式错误",
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({ userInfo: "user/userInfo" }),
  },
  methods: {
    beforeUpload(file) {
      const isAcceptType = AcceptImgType.includes(file.type);
      const isWithinlimits = file.size <= UploadImgLimit;
      if (!isAcceptType) {
        this.$myToast({
          type: "error",
          message: "格式错误",
        });
      }
      if (!isWithinlimits) {
        this.$myToast({
          type: "error",
          message: "图片大小不能超过10M",
        });
      }
      const pass = isAcceptType && isWithinlimits;
      // if (pass) {
      //   this.fileList = [file];
      // }
      return pass;
    },
    customRequest(file) {
      console.log(file);
      const formData = new FormData();
      formData.append("file", file.file);
      uploadFile(formData)
        .then((res) => {
          this.uploadErrorText = "";
          this.previewImgUrl = res.fullPath;
          this.companyCertImage = res.path;
          file.onSuccess(res, file);
          this.fileList = [file.file];
        })
        .catch((err) => {
          this.uploadErrorText = err;
          this.previewImgUrl = "";
          this.companyCertImage = "";
          this.fileList = [];
        });
    },
    handleAgrmet(rule, value, callback) {
      if (value) callback();
      else callback("请同意平台用户协议");
    },
    handleChange(info) {
      console.log("change", info);
      // if (info.file.type === "image/jpeg" || info.file.type === "image/png") {
      // this.fileList = info.fileList;
      // }
    },
    // handleRemove(file) {
    //   const index = this.fileList.indexOf(file);
    //   const newFileList = this.fileList.slice();
    //   newFileList.splice(index, 1);
    //   this.fileList = newFileList;
    // },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, fieldsValue) => {
        console.log(fieldsValue);
        if (!err) {
          const {
            companyAddress,
            // companyCertImage,
            companyCertType,
            companyName,
            companySocialCode,
            realName,
            idCard,
            smsCode,
          } = fieldsValue;
          // const personalAuthParams = {
          //   idCard,
          //   name: realName,
          // };
          const companyAuthParams = {
            companyAddress,
            companyCertImage: this.companyCertImage,
            companyCertType,
            companyName,
            companySocialCode,
            idCard,
            realName,
            smsCode,
            phone: this.userInfo.phone,
          };

          // Promise.all([
          //   this.legalPersonAuth(personalAuthParams),
          //   this.companyCert(companyAuthParams),
          // ]);
          this.companyCert(companyAuthParams).then(() => {
            this.$refs.authSubmitSuccessModal.setModalVisible(true);
            setTimeout(() => {
              this.form.resetFields();
              this.$router.push("/user/info");
            }, 500);
          });
        }
      });
    },
    companyCert(params) {
      return cert(params);
    },
    legalPersonAuth(params) {
      return personalAuth(params);
    },
    resendCountDown() {
      this.sendTimer = setInterval(() => {
        this.countDownNum--;
        if (this.countDownNum <= 0) {
          this.sendSmsCode = false;
          this.countDownNum = 59;
          clearInterval(this.sendTimer);
        }
      }, 1000);
    },
    getSmsCode() {
      let params = {
        phone: this.userInfo.phone,
      };
      smsCode(params).then(() => {
        message.success("验证码已发送");
        this.sendSmsCode = true;
        this.resendCountDown();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/scss/helper.scss";
@import "@/styles/scss/variants.scss";

.ant-form {
  width: 100%;
  //@include flex(column, null, center);
}

.enterprise-auth-form {
  //width: 640px;
  //width: 660px;
  overflow: hidden;
  margin: 80px auto 0;
  position: relative;

  .center {
    width: 640px;
    margin: 0 auto;
  }

  .upload-container {
    width: 320px;
    height: 180px;
    background: #eef4ff;
    border-radius: 4px;
    @include flex(column, null, center);
    //padding-top: 50px;
    line-height: 1;

    .icon-daochu-11 {
      margin-top: 50px;
      font-size: 34px;
      color: #aab1d1;
    }

    .img-uploader {
      &.has-upload {
        width: 320px;
        height: 180px;

        img {
          width: 320px;
          height: 180px;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }

    .error-tip {
      font-size: 14px;
      font-weight: 400;
      color: #ec5d62;
    }
  }

  .upload-tip {
    word-break: break-all;
    width: 320px;
    color: #6c728c;
    line-height: 24px;
    font-size: 12px;
    margin-top: 20px;
  }

  .verify-code-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .verify-code-button {
    font-size: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 40px;
    padding: 0;

    &[disabled="disabled"] {
      opacity: 0.5;
    }
  }

  .statement {
    margin-top: 50px;
    //width: 336px;
    //margin-left: 260px;
    margin-bottom: 20px !important;

    ::v-deep .ant-checkbox-wrapper {
      line-height: 27px;
      font-size: 16px;
      color: $primary-text-color;
    }

    .agreement {
      position: relative;
      z-index: 2;
    }

    span {
      color: $primary-text-color;
    }
  }

  .ant-form-item {
    //display: flex;
    margin-bottom: 10px;

    ::v-deep .ant-form-item-label {
      //width: 320px;
      color: $primary-text-color;
    }
    input {
      //width: 320px;
    }

    .ant-select {
      //width: 320px;
    }
  }

  .divide {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #c4d6fc;
    margin-top: 26px;
    margin-bottom: 34px;
  }

  .legal-person {
    //margin-top: 70px;
  }
}
</style>
