<template>
  <div class="content-container">
    <card-title title="企业认证" description="1~3个工作日完成审核" />
    <enterprise-auth-form />
  </div>
</template>
<script>
import EnterpriseAuthForm from "@/views/user/enterprise-auth/EnterpriseAuthForm";
import CardTitle from "@/components/CardTitle";
export default {
  components: { CardTitle, EnterpriseAuthForm },
  data() {
    return {};
  },
  beforeCreate() {},
  methods: {},
};
</script>
+

<style lang="scss" scoped></style>
